<template>
  <div class="steps">
    <BoxItem v-for="(item, index) in data"
      :key="index"
      :text="item.text"
      :lists="item.lists"
      :type="item.type"
      :index="index"
    />
  </div>
</template>
<script>
import BoxItem from './box-item'
import { data } from './data.json'
export default {
  components: {
    BoxItem
  },
  data () {
    return {
      data
    }
  }
}
</script>
<style lang="less" scoped>
  .steps {
    width: 1200px;
    margin: 0 auto;
    position: relative;
    margin-bottom: 60px;
    padding-top: 20px;
    &:after {
      position: absolute;
      content: " ";
      left: 50%;
      top: 25px;
      bottom: 0px;
      width: 2px;
      background: #254c9b;
    }
  }
</style>
