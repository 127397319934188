<template>
  <div class="containers">
    <MainHeader />
    <Banner :bgImg="bgImg" :TopTitle="TopTitle" :secondTitle="secondTitle">
    </Banner>
    <!-- 加入我们 -->
    <Box>
      <div class="tt">
        <img src="http://www.lndfwljt.com/image/2021/07/09/lianxi.png"/>
      </div>
      <div class="part flex-btwn">
        <div class="part-left">
          <h2>辽宁大方未来控股集团有限公司</h2>
          <div class="line">
            公司地址：沈阳市浑南区航天路16号<br/>
            联系电话：400-1004-789<br/>
            邮政编码：110179<br/>
            官方网址：www.lndfwljt.com<br/>
          </div>
        </div>
        <div class="part-right">
          <iframe width="600" height="300" frameborder='0' scrolling='no' marginheight='0' marginwidth='0' src="https://lbs.amap.com/tools/showmap/?1_500_400_123.417116_41.702807&=%E5%A4%A7%E6%96%B9%E6%9C%AA%E6%9D%A5%E9%9B%86%E5%9B%A2&=%E8%88%AA%E5%A4%A9%E8%B7%AF&=&=&=%E5%A4%A7%E6%96%B9%E6%9C%AA%E6%9D%A5%E9%9B%86%E5%9B%A2"></iframe>
        </div>
      </div>
    </Box>
    <MainFooter />
  </div>
</template>
<script>
import MainFooter from '../public/footer'
import MainHeader from '../public/header'
import Banner from '../public/banner'
import Box from '../public/box'

export default {
  components: {
    MainFooter,
    MainHeader,
    Banner,
    Box
  },
  data () {
    return {
      bgImg: 'http://www.lndfwljt.com/image/2021/07/09/061424.jpg',
      TopTitle: '联系我们',
      secondTitle: 'CONTACT US'
    }
  }
}
</script>
<style lang="less" scoped>
  .banner-item {
    margin: 0 auto;
    padding-top: 260px;
    color: #fff;
    .tit {
      font-size: 72px;
      font-weight: bold;
      margin-bottom: 20px;
      letter-spacing: 10px;
    }
    .subtit {
      font-size: 36px;
    }
  }
  .container {
    .title {
      text-align: center;
      margin: 30px 0;
    }
  }
  .part {
    width: 1200px;
    margin: 0 auto;
    margin-bottom: 60px;
    &-left {
      color: #000;
      h2 {
        font-weight: bold;
      }
      .line {
        line-height: 3;
      }
    }
  }
  .part2 {
    img { width: 100% }
    margin-bottom: 40px;
  }
  .part3 {
    margin-bottom: 120px;
    .item {
      margin-bottom: 40px;
      h2 {
        text-align: center;
        font-size: 26px;
      }
      p {
        text-align: center;
        font-size: 20px;
      }
    }
  }
  .tt {
    margin-top: 20px;
    margin-bottom: 50px;
    margin-left: 600px;
    position: center;
  }
  .part-right img {
    width: 600px;
    height: 300px;
  }
</style>
