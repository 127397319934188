<template>
  <div class="main">
    <PartTitle title="核心价值观" :src="tit1" />
    <div class="w1200 flex-btwn mb40 relative">
      <Item v-for="(item, index) in ITEMS" :key="index" :item="item" />
    </div>
  </div>
</template>
<script>
import PartTitle from '../../../public/box-title'
import Item from './item'

import { data as ITEMS } from './data.json'

export default {
  components: {
    Item,
    PartTitle
  },
  data () {
    return {
      bg: 'http://www.lndfwljt.com/image/2021/07/09/052304.png',
      tit1: 'http://www.pekhongyuan.com/pic/hongyuan/images/052304.png',
      tit2: 'http://www.pekhongyuan.com/pic/hongyuan/images/060710.png',
      ITEMS
    }
  },
  mounted () {
    window.scrollTo(0, 500)
  }
}
</script>
<style lang="less">
  .main {
    margin: 0 auto;
    .w800 {
      width: 640px;
      margin: 0 auto;
    }
    .w800 > p,
    .w800 > div {
      color: white !important;
    }
    .mb40 {
      margin-bottom: 80px;
    }
    .part {
      margin-bottom: 120px;
      width: 1919px;
      height: 545px;
      background: url('http://www.pekhongyuan.com/pic/hongyuan/images/060713.png')
      no-repeat;
      background-size: 100% 100%;
      text-align: center;
    }
  }
</style>
