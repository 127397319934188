<template>
  <div :class="className">
    <slot />
  </div>
</template>
<script>
export default {
  props: {
    type: {
      type: String,
      default: ''
    }
  },
  computed: {
    className () {
      return [
        'box',
        `${this.type}`
      ]
    }
  }
}
</script>
<style lang="less" scoped>
.box {
  width: 100%;
  padding-top: 40px;
  margin-bottom: 40px;
}
.style1 {
  min-width: 1200px;
  padding-top: 40px;
  margin-bottom: 40px;
  background: url('http://www.lndfwljt.com/image/2021/07/09/bg2.jpg') no-repeat center top;
  background-size: 100% 100%;
}
</style>
