<template>
  <div class="banner">
    <div style="font-size: 72px" v-show="TopTitle !=null"><b>{{TopTitle}}</b></div>
    <div style="font-size: 36px" >
      <b v-show="secondTitle !=null">{{secondTitle}}</b>
      <br>
      <b v-show="thirdTitle !=null">{{thirdTitle}}</b>
    </div>
    <img :src="src"/>
  </div>
</template>
<script>
export default {
  props: ['src', 'TopTitle', 'secondTitle', 'thirdTitle']
}
</script>
<style lang="less" scoped>
.banner {
  width: 100%;
  min-width: 1200px;
  position: relative;
  height: auto;

  img {
    width: 100%;
    min-width: 1200px;
    z-index: 1;
  }

  div:first-child {
    position: absolute;
    top: 30%;
    left: 11%;
    z-index: 100;
    color: white;
    letter-spacing:10px;
  }

  div:nth-child(2) {
    position: absolute;
    top: 47%;
    left: 11%;
    z-index: 100;
    color: white;
    letter-spacing:10px;
  }
}

</style>
