<template>
<div :class="wrapClass" v-if="showBox">
  <div :class="yearClass">{{ data.year }}</div>
  <div :class="titleClass" v-html="data.title" />
</div>
<div v-else class="steps-list-item"></div>
</template>
<script>
import className from 'classnames'

export default {
  props: {
    data: {
      type: Object,
      default: () => ({})
    },
    type: {
      type: Number,
      default: 0
    },
    visible: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      animated: false,
      show: false
    }
  },
  watch: {
    visible (visibile) {
      if (visibile && !this.animated) {
        this.show = true
        this.animated = true
      }
    }
  },
  computed: {
    yearClass () {
      return className('year', {
        left: this.type === 0,
        right: this.type === 1
      })
    },
    titleClass () {
      return className('title', {
        left: this.type === 1,
        right: this.type === 0
      })
    },
    wrapClass () {
      return className('steps-list-item', 'animate__animated', this.data.class, {
        left: this.type === 0,
        right: this.type === 1,
        animate__fadeInUp: this.show
      })
    },
    showBox () {
      return this.show || this.animated
    }
  }
}
</script>
<style scoped lang="less">
  .steps-list-item {
    display: flex;
    color: #3d3e3f;
    position: relative;
    font-size: 16px;
    &.left {
      margin-left: 40px;
      &:before {
        position: absolute;
        content: ' ';
        border-radius: 50%;
        width: 10px;
        height: 10px;
        top: 6px;
        left: -44px;
        background: #254c9b;
      }
    }
    &.first-item {
      &.left {
        margin-left: 40px;
        &:before {
          position: absolute;
          content: ' ';
          border-radius: 50%;
          width: 20px;
          height: 20px;
          border: 1px solid #254c9b;
          top: 2px;
          left: -49px;
          background: #fff;
        }
        &:after {
          position: absolute;
          content: ' ';
          border-radius: 50%;
          width: 14px;
          height: 14px;
          top: 5px;
          left: -46px;
          background: #254c9b;
        }
      }
    }
    &.right {
      justify-content: flex-end;
      margin-right: 40px;
      &:before {
        position: absolute;
        content: ' ';
        border-radius: 50%;
        width: 10px;
        height: 10px;
        top: 6px;
        right: -46px;
        background: #254c9b;
      }
    }
    .year {
      &.left {
        margin-right: 20px;
        order: 1;
      }
      &.right {
        order: 2;
      }
    }
    .title {
      &.left {
        margin-right: 20px;
        order: 1;
        text-align: right;
      }
      &.right {
        order: 2;
      }
    }
  }
</style>
