<template>
  <div :class="className" v-if="showBox">
    <div>
      <div class="steps-box-tit"
        v-for="line in text" :key="line">
        {{ line }}
      </div>
    </div>
  </div>
  <div v-else></div>
</template>
<script>

export default {
  props: {
    text: {
      type: Array,
      default: () => []
    },
    visible: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      animated: false,
      show: false
    }
  },
  watch: {
    visible (visibile) {
      if (visibile && !this.animated) {
        this.show = true
        this.animated = true
      }
    }
  },
  computed: {
    className () {
      return [
        'tit-container',
        'animate__animated',
        { animate__fadeInUp: this.show }
      ]
    },
    showBox () {
      return this.show || this.animated
    }
  }
}
</script>
<style lang="less" scoped>
  .tit-container {
    align-items: center;
    justify-content: center;
    display: flex;
    height: 100%;
  }
  .steps-box {
    &-tit {
      color: #336fd0;
      font-size: 24px;
      text-align: center;
      font-weight: bold;
      margin-bottom: 10px;
    }
  }
</style>
