<template>
  <div class="main">
    <MainHeader />
    <Banner :bgImg="bgImg" :TopTitle="TopTitle" :secondTitle="secondTitle">
      <div class="banner-item w1200 align-center">
      </div>
    </Banner>
    <!-- 中间内容部分 -->
    <PartTitle :src="tit1" />
    <Box type="style1">
      <div class="w1200 part2 container">
        <div class="l w35">
          <video controls autoplay style="position: center;width: 150%" src="http://www.lndfwljt.com/image/2021/07/09/电商.mp4" />
        </div>
        <div class="r w65 pl20">
          <ul class="list">
            <li>辽宁大方未来科技有限公司是一家集医药电商B2C、O2O,线上、线下相结合的医药商务企业。</li>
            <li>依托互联网平台，广泛开展与互联网公司、医药工业公司、线下零售药店、物流公司之间的业务合作，是一个多元化的医药互联网商城。</li>
          </ul>
        </div>
      </div>
    </Box>
    <!-- part5 -->
    <Box type="style1">
      <div class="w1200 part3 container">
        <div class="l w65">
          <ul class="list">
            <li>目前，公司已入驻天猫商城、京东商城、饿百、美团外卖、拼多多等现代化电子商务平台。</li>
          </ul>
        </div>
        <div class="r w35">
          <img src="http://www.lndfwljt.com/image/2021/07/09/dianshang2.jpg" />
        </div>
      </div>
    </Box>
    <!-- part2 -->
    <MainFooter />
  </div>
</template>
<script>
import MainFooter from '../public/footer'
import MainHeader from '../public/header'
import Banner from '../public/banner'
import PartTitle from '../public/box-title'
import Box from '../public/box'

export default {
  data () {
    return {
      bgImg: 'http://www.lndfwljt.com/image/2021/07/09/eCommerce.jpg',
      tit1: 'http://www.lndfwljt.com/image/2021/07/09/dianshang.png',
      current: '1',
      TopTitle: '电子商务',
      secondTitle: 'E-COMMERCE'
    }
  },
  components: {
    MainFooter,
    MainHeader,
    Banner,
    PartTitle,
    Box
  }
}
</script>
<style lang="less" scoped>
  .banner-item {
    margin: 0 auto;
  }
  .part1 {
    line-height: 2.5;
    color: #555;
    margin-bottom: 40px;
    .row {
      margin-bottom: 40px;
    }
    text-align: center;
  }
  .w35 { width: 35% }
  .w65 { width: 45%;margin-top: 180px}
  .pl20 { padding-left: 20px }
  .container {
    display: flex;
    justify-content: space-between;
    padding-bottom: 40px;
    img {
      width: 100%;
    }
    h4 {
      font-size: 20px;
    }
    .list {
      li {
        margin-bottom: 20px;
        position: relative;
        padding-left: 20px;
        &::before {
          content: ' ';
          position: absolute;
          left: 10px;
          top: 10px;
          width: 10px;
          height: 10px;
          margin-left: -5px;
          margin-top: -5px;
          background: #d2d2d2;
          border-radius: 50%;
        }
      }
    }
  }
</style>
