export const ICON = [{
  tit: 'WHOLESALE LOGISTICS',
  subTit: '批发物流',
  icon: 'icon-bg1',
  hicon: 'icon-bg1-hover',
  link: '/wholesaleLogistics'
}, {
  tit: 'RETAIL CHAIN',
  subTit: '零售连锁',
  icon: 'icon-bg4',
  hicon: 'icon-bg4-hover',
  link: '/retailChain'
}, {
  tit: 'BIOTECHNOLOGY',
  subTit: '生物科技',
  icon: 'icon-bg3',
  hicon: 'icon-bg3-hover',
  link: '/biologicalTechnology'
}, {
  tit: 'E-COMMERCE',
  subTit: '电子商务',
  icon: 'icon-bg0',
  hicon: 'icon-bg0-hover',
  link: '/eCommerce'
}, {
  tit: 'PRODUCTION R & D',
  subTit: '生产研发',
  icon: 'icon-bg5',
  hicon: 'icon-bg5-hover',
  link: '/prd'
}, {
  tit: 'HEALTHY INCUBATION',
  subTit: '健康孵化',
  icon: 'icon-bg2',
  hicon: 'icon-bg2-hover',
  link: '/healthHatch'
}]
