export const data = [{
  src: 'http://www.lndfwljt.com/image/2021/07/09/052306.png',
  title: '企业理念',
  hover: {
    bg: 'http://www.lndfwljt.com/image/2021/07/09/060708.png'
  }
}, {
  src: 'http://www.lndfwljt.com/image/2021/07/09/052307.png',
  title: '企业精神',
  hover: {
    bg: 'http://www.lndfwljt.com/image/2021/07/09/060706.png'
  }
}, {
  src: 'http://www.lndfwljt.com/image/2021/07/09/052308.png',
  title: '企业宗旨',
  hover: {
    bg: 'http://www.lndfwljt.com/image/2021/07/09/060704.png'
  }
}]
