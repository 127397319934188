<template>
  <div class="main">
    <MainHeader />
    <Banner :bgImg="bgImg" :TopTitle="TopTitle" :secondTitle="secondTitle">
      <div class="banner-item w1200 align-center">
      </div>
    </Banner>
    <!-- 中间内容部分 -->
    <PartTitle :src="tit1" />
    <!-- part2 -->
    <Box type="style1">
      <div class="w1200 part2 container">
        <div class="l w35">
          <video controls autoplay style="position: center;width: 150%" src="http://www.lndfwljt.com/image/2021/07/09/生物科技.mp4" />
        </div>
        <div class="r w65 pl20">
          <ul class="list">
            <li>辽宁九丰生物科技有限公司成立于2018年5月，注册资本2000万元。</li>
            <li>公司是一家专业从事医疗器械研发及销售的公司，目前主营业务为临床检验仪器及体外诊断试剂产品的销售、区域性肾病防治中心的建设。</li>
            <li>2019年，集团与西班牙BIOSTEC等公司强强联手，打造尿特种蛋白及尿生化整体解决方案，客户遍布辽宁、吉林省各大三甲、地级市中心医院，成为东北三省肾脏疾病检测、诊断、治疗领域的第一品牌。</li>
            <li>公司与中国医科大学附属盛京医院、沈阳市第四人民医院、大连医科大学附属第一医院、辽宁中医药大学附属医院开展业务合作，为高端医疗器械研发、销售开辟广泛医院市场。</li>
          </ul>
        </div>
      </div>
    </Box>
    <!-- part5 -->
    <Box type="style1">
      <div class="w1200 part3 container">
        <div class="l w65">
          <ul class="list">
            <li>公司结合中国医科大学附属第一医院共建辽宁省肾脏病一体化管理及防治中心，联合全省14家各地级市三甲医院参与分层抽查检测，针对CKD患者蛋白尿开展精准尿液生物标志物检测，筛查出的存在肾脏疾病患者可转入对口的三甲医院，进行疾病的进一步诊疗。</li>
            <li>公司依托互联网平台，广泛开展与互联网公司、医药工业公司、线下零售药店、物流公司之间的业务合作，是一个多元化的医药互联网商城。</li>
            <li>同时，依托中心开展的慢性肾病与妊高症尿液早期筛查项目、肾小球肾炎尿液联合检测推荐项目、糖尿病肾病（DKD）尿液联合检测推荐项目、IgA肾病（IgAN）尿液联合检测推荐项目、急性肾损伤（AKI）尿液联合检测推荐项目、药物性肾损伤尿液联合检测推荐项目、尿路结石尿液检测项目等，收集、整理获取的相关临床数据，为完成指导临床诊疗的肾脏病提供共识或数据指南。</li>
          </ul>
        </div>
        <div class="r w35">
          <img src="http://www.lndfwljt.com/image/2021/07/09/生物.jpg" />
        </div>
      </div>
    </Box>
    <MainFooter />
  </div>
</template>
<script>
import MainFooter from '../public/footer'
import MainHeader from '../public/header'
import Banner from '../public/banner'
import PartTitle from '../public/box-title'
import Box from '../public/box'

export default {
  data () {
    return {
      bgImg: 'http://www.lndfwljt.com/image/2021/07/09/keji.jpg',
      tit1: 'http://www.lndfwljt.com/image/2021/07/09/keji.png',
      current: '1',
      TopTitle: '生物科技',
      secondTitle: 'BIOTECHNOLOGY'
    }
  },
  components: {
    MainFooter,
    MainHeader,
    Banner,
    PartTitle,
    Box
  }
}
</script>
<style lang="less" scoped>
  .banner-item {
    margin: 0 auto;
  }
  .part1 {
    line-height: 2.5;
    color: #555;
    margin-bottom: 40px;
    .row {
      margin-bottom: 40px;
    }
    text-align: center;
  }
  .w35 { width: 35% }
  .w65 { width: 45% }
  .pl20 { padding-left: 20px }
  .container {
    display: flex;
    justify-content: space-between;
    padding-bottom: 40px;
    img {
      width: 100%;
    }
    h4 {
      font-size: 20px;
    }
    .list {
      li {
        margin-bottom: 20px;
        position: relative;
        padding-left: 20px;
        &::before {
          content: ' ';
          position: absolute;
          left: 10px;
          top: 10px;
          width: 10px;
          height: 10px;
          margin-left: -5px;
          margin-top: -5px;
          background: #d2d2d2;
          border-radius: 50%;
        }
      }
    }
  }
</style>
