<template>
  <div class="main">
    <MTitle :src="tit1" />
    <div class="bg">
      <Box type="style1" style="height: 280px; margin-bottom: 280px;"></Box>
      <Box type="style1" style="height: 280px; margin-bottom: 280px;"></Box>
    </div>
    <Steps />
  </div>
</template>
<script>
import Box from '../../../public/box'
import Steps from './steps/index'
import MTitle from '../../../public/box-title'

export default {
  components: {
    Box,
    Steps,
    MTitle
  },
  data () {
    return {
      tit1: 'http://www.lndfwljt.com/image/2021/07/09/tit10.png'
    }
  },
  mounted () {
    window.scrollTo(0, 500)
  }
}
</script>
<style scoped lang="less">
  .main {
    position: relative
  }
  .main .bg {
    position: absolute;
    left: 0;
    right: 0;
  }
</style>
