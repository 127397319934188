<template>
  <div class="header">
    <div class="header-panel">
        <div class="logo"><a href="#"><img alt="宏远控股" src="http://www.lndfwljt.com/image/2021/07/09/logo.png"/></a></div>
        <div class="navAll">
          <Menus />
        </div>
    </div>
</div>
</template>
<script>
import Menus from './menu'

export default {
  name: 'MainHeader',
  components: {
    Menus
  }
}
</script>
<style lang="less" scoped>
// 加一个端点
@media screen and (max-width: 1199px) {
    .header {
      width: 1200px;
    }
}
.header{
  width:100%;
  position:absolute;
  top:0;
  z-index:1000;
  min-width: 1200px;
}
.header-panel{
  max-width:1520px;
  min-width:1200px;
  margin-left: 11%;
  margin-right: 11%;
  padding:20px 0;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  .logo{
    img {
      width: 140px;
    }
  }
}
</style>
