<template>
  <div :class="className" @click="handleSelect">
    <a class="text">{{ data.name }}</a>
  </div>
</template>
<script>
export default {
  props: {
    data: {
      type: Object,
      default: () => ({})
    },
    current: {
      type: [Number, String]
    }
  },
  computed: {
    className () {
      return [
        'h-menus-item',
        {
          current: this.current === this.data.value
        }
      ]
    }
  },
  methods: {
    handleSelect () {
      this.$emit('select', this.data.value)
    }
  }
}
</script>
<style lang="less" scoped>
.h-menus-item {
  flex: 1;
  text-align: center;
  .text {
    font-size: 20px;
    color: #333;
    line-height: 90px;
  }
  &.current {
    .text {
      color: #336fd0;
      font-weight: bold;
    }
    border-bottom: 2px solid #336fd0;
  }
}
</style>
