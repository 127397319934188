<template>
  <div class="main">
    <MainHeader />
    <Banner :bgImg="bgImg" :TopTitle="TopTitle" :secondTitle="secondTitle">
      <div class="banner-item w1200 align-center">
      </div>
    </Banner>
    <!-- 中间内容部分 -->
    <PartTitle :src="tit1" />
    <!-- part2 -->
    <Box type="style1">
      <div class="w1200 part2 container">
        <div class="l w35">
          <video controls autoplay style="position: center;width: 150%" src="http://www.lndfwljt.com/image/2021/07/09/五洲通.mp4" />
        </div>
        <div class="r w65 pl20">
          <ul class="list">
            <li>辽宁五洲通大药房连锁有限公司2014年入驻沈城，是一家迅速崛起的大型医药零售连锁企业。</li>
            <li>目前，通过直接运营、委托管理、招商加盟三种模式以沈阳为中心在辽宁省内开设零售连锁药店300余家，会员数量近100万，经营品规9000余个，与东北制药、哈药集团、广药集团等近千家制药厂商建立合作关系。</li>
            <li>公司致力于追求百姓健康服务，打造民众健康品牌。</li>
            <li>为了保证产品、服务质量，公司配备了专业药学服务人员，与全国两千余家知名品牌药企建立了合作关系，经营品规达万余种。</li>
          </ul>
        </div>
      </div>
    </Box>
    <!-- part5 -->
    <Box type="style1">
      <div class="w1200 part3 container">
        <div class="l w65">
          <ul class="list">
            <li>公司与辽宁中医药大学、沈阳药科大学、沈阳医学院等高等院校建立人才输送渠道，为广大消费者提供了专业、便捷的健康服务平台。</li>
            <li>集团依托产业背景和上下游链条资源搭建医药电商平台、社区一体化医药平台、医药大数据平台、远程审方检测平台，为实现智慧诊疗、智慧医药、智慧医院的有机结合，三甲级医院横向互联互通，三甲到二甲到社区医院纵向互联互通奠定了基础。在智慧诊疗、仓储物流、医药配送、社区药房、居家康养等领域深入开展合作，实现共享资源，创新发展提供广阔的医药市场平台。</li>
            <li>公司汇聚中国医科大学附属第一医院、辽宁省中医院等中医名师坐诊“国医馆”，定期组织课题交流培训会，免费就诊居民患者，形成以中药医学为纽带的生态社群，为创业者深入研究合作提供医药市场服务。</li>
          </ul>
        </div>
        <div class="r w35">
          <img src="http://www.lndfwljt.com/image/2021/07/09/五洲通03.jpg" />
        </div>
      </div>
    </Box>
    <MainFooter />
  </div>
</template>
<script>
import MainFooter from '../public/footer'
import MainHeader from '../public/header'
import Banner from '../public/banner'
import PartTitle from '../public/box-title'
import Box from '../public/box'

export default {
  data () {
    return {
      bgImg: 'http://www.lndfwljt.com/image/2021/07/09/lingshou.jpg',
      tit1: 'http://www.lndfwljt.com/image/2021/07/09/lingshou.png',
      current: '1',
      TopTitle: '零售连锁',
      secondTitle: 'RETAIL CHAIN'
    }
  },
  components: {
    MainFooter,
    MainHeader,
    Banner,
    PartTitle,
    Box
  }
}
</script>
<style lang="less" scoped>
  .banner-item {
    margin: 0 auto;
  }
  .part1 {
    line-height: 2.5;
    color: #555;
    margin-bottom: 40px;
    .row {
      margin-bottom: 40px;
    }
    text-align: center;
  }
  .w35 { width: 35% }
  .w65 { width: 45% }
  .pl20 { padding-left: 20px }
  .container {
    display: flex;
    justify-content: space-between;
    padding-bottom: 40px;
    img {
      width: 100%;
    }
    h4 {
      font-size: 20px;
    }
    .list {
      li {
        margin-bottom: 20px;
        position: relative;
        padding-left: 20px;
        &::before {
          content: ' ';
          position: absolute;
          left: 10px;
          top: 10px;
          width: 10px;
          height: 10px;
          margin-left: -5px;
          margin-top: -5px;
          background: #d2d2d2;
          border-radius: 50%;
        }
      }
    }
  }
</style>
