<template>
  <div>
    <MainHeader />
    <MainBanner :src='bannerSrc' :TopTitle="TopTitle" :secondTitle="secondTitle"/>
    <Menus
      :menus="menus"
      class="fix-menus"
      :current="currentType"
      @select="handleSelect"
    />
    <GoupNews v-if="current === '1'" />
    <NewsList v-if="current === '2'" />
    <Article v-if="current === '3'" />
    <MainFooter />
  </div>
</template>
<script>
import MainHeader from '../public/header'
import Menus from '../public/h-menus'
import MainBanner from '../public/simpleBanner'
import MainFooter from '../public/footer'
import NewsList from './components/news-list'
import GoupNews from './components/group-news'
import Article from './components/article'
import { data as menus } from './menus.json'
// import bannerImg from 'http://www.lndfwljt.com/image/2021/07/09/banner-news.jpg'

export default {
  data () {
    return {
      bannerSrc: 'http://www.lndfwljt.com/image/2021/07/09/banner-news.jpg',
      menus, // 菜单json
      current: '1',
      currentType: '1',
      TopTitle: '新闻资讯',
      secondTitle: 'NEWS'
    }
  },
  watch: {
    '$route.params': {
      handler (params) {
        console.log('watch')
        if (params.type) {
          this.current = params.type
          this.currentType = params.type
        }
      },
      deep: true
    }
  },
  created () {
    console.log(this.$route)
    const current = this.$route.params
    console.log('created')
    console.log(this.$route.params)
    if (current.type === '3') {
      this.current = current.type
      this.currentType = current.currentType
    } else {
      this.current = current.type
      this.currentType = current.type
    }
  },
  methods: {
    handleSelect (v) {
      if (v === this.current) return
      this.current = v
      this.currentType = v
      this.$router.push({
        name: 'News',
        params: { type: v }
      })
    }
  },
  components: {
    MainHeader,
    Menus,
    MainBanner,
    MainFooter,
    NewsList,
    GoupNews,
    Article
  }
}
</script>
<style lang="less" scoped>
  .main {
    min-width: 1200px;
  }
  .fix-menus {
    margin-top: -90px;
    z-index: 100;
    position: relative;
  }
</style>
