<template>
  <div>
    <div class='title'>
      <img src='http://www.lndfwljt.com/image/2021/07/09/title-news2.png' alt='媒体报道' />
    </div>
    <NewsSwiper :detail='newsOutline' :type=type />
    <div class="news-list container">
      <ul>
        <NewsItem :detail='item' v-for="(item,index) in newList" :key="index" />
      </ul>
      <div class="loadingList" v-show="more" @click="loadingList(mediaPageIndex)"><img src="http://www.lndfwljt.com/image/2021/07/09/ic-arrow-b.png" /></div>
    </div>
  </div>
</template>
<script>
import NewsItem from './news-item'
import NewsSwiper from './news-swiper'
import { fetchMainNewLists } from '@/api/news'
export default {
  components: { NewsItem, NewsSwiper },
  data () {
    return {
      mediaPageIndex: 1,
      newList: [],
      newsOutline: {},
      // 下拉是否显示
      more: true,
      // 新闻类型 1是媒体报道
      type: 1
    }
  },
  async mounted () {
    const data = await fetchMainNewLists({ type: 1, pageNum: 1, isTop: 0 })
    if (data.code === 200) {
      this.newList = data.data.records
    }
  },
  methods: {
    goNew (item) {
      this.$router.push({
        name: 'newsDetail',
        params: {
          type: item.type,
          pageId: item.id
        }
      })
    },
    async loadingList (pageIndex) {
      this.mediaPageIndex += 1
      const moreList = await fetchMainNewLists({ type: 1, pageNum: this.mediaPageIndex, isTop: 0 })
      if (moreList.data.records != null && moreList.code === 200) {
        this.newList = this.newList.concat(moreList.data.records)
        console.log(this.newList)
        if (this.mediaPageIndex >= moreList.data.totalPages) {
          this.more = false
        }
      }
    }
  }
}
</script>
<style lang='less' scoped>
p, dl{
  margin-bottom: 0;
}
.title {
  margin-top: 60px;
  margin-bottom: 80px;
  text-align: center;
}
.container {
  width:100%;
  max-width: 1523px;
  min-width: 1200px;
  margin: 0 auto;
}
.news-list{
  padding: 50px;
  box-sizing: border-box;
  li+li{
    border-top: 1px solid #d2d2d2;
  }
}
.loadingList{
  width: 100%;
  padding: 40px 20px;
  text-align: center;
  cursor: pointer;
}
</style>
