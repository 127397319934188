<template>
  <div class="news-top">
    <a-carousel :autoplay="false" dotsClass="dots-click" arrows class="banner-swiper">
      <!-- 箭头 -->
      <div
        slot="prevArrow"
        class="custom-slick-arrow arrow-prev"
        style="left: 10px;zIndex: 1"
      />
      <div slot="nextArrow"
        class="custom-slick-arrow arrow-next"
        style="right: 10px"
      />
      <!-- 箭头end -->
      <div class="swiper-slide" v-for="(item,index) in newList" :key="index">
        <div class="swiper-slide-item">
          <dl class="news-main flex-btwn" @click="goNew (item)">
            <dt><a target="_blank" @click="goNew(item)"><img :src="item.coverMap"/></a></dt>
            <dd>
                <p class="tit" @click="goNew (item)">{{ item.title }}</p>
                <p class="date">{{ item.createTime }}</p>
                <p class="txt">{{ item.newAbstract || '暂无摘要'}}</p>
                <div class="bttm">
                  <div v-show="item.original_title!=null && item.original_title!=undefined">
                    <p>{{ item.original_title }}</p>
                    <p>
                      {{ item.link }}
                      <span class="ml-100">{{ item.baseDate }}</span>
                    </p>
                  </div>
                  <a target="_blank" @click="goNew(item)">了解更多></a>
                </div>
            </dd>
          </dl>
        </div>
      </div>
    </a-carousel>
  </div>
</template>
<script>
import { fetchMainNewLists } from '@/api/news'

export default {
  props: ['detail', 'type'],
  data () {
    return {
      newList: []
    }
  },
  async mounted () {
    const data = await fetchMainNewLists({ type: this.type, pageNum: 1, isTop: 1 })
    console.log(data)
    if (data.code === 200) {
      this.newList = data.data.records
    }
  },
  methods: {
    goNew (item) {
      this.$router.push({
        name: 'newsDetail',
        params: {
          type: item.type,
          pageId: item.id
        }
      })
    }
  }
}
</script>
<style lang='less' scoped>
.news-top{
  width:100%;
  min-width: 12rem;
  padding-top:0.4rem;
  margin-bottom: 0.4rem;
  background: url('http://www.lndfwljt.com/image/2021/07/09/bg2.jpg') no-repeat center top;
  background-size: auto 100%;
}
.banner-swiper{
  width:100%;
  max-width: 15.23rem;
  min-width: 12rem;
  margin: 0 auto;
  position: relative;
}
.news-main {
  width: 100%;
  padding: 0 0.6rem;
  margin: 0 auto 0.3rem;
  box-sizing: border-box;
  &:hover{
    .tit{
      color: #336fd0;
    }
  }
  dt {
    width: 50%;
    padding-top: 10px;
    margin-right: 40px;
    box-sizing: border-box;
    img {
      width: 100%;
      height: auto;
    }
  }
  dd {
    width: 50%;
    padding: 0 0 100px;
    box-sizing: border-box;
    position: relative;
    .tit{
      margin-bottom: 20px;
      font-size: 16px;
      color: #333;
      font-weight: bold;
      line-height: 2;
    }
    .txt{
      margin-bottom: 20px;
      font-size: 14px;
      color: #555;
      line-height: 2;
    }
    .date {
      font-size: 14px;
      color: #555;
    }
    .f-link{
      font-size: 10px;
      color: #336fd0;
      text-align: right;
    }
    .bttm {
      width: 100%;
      margin-bottom: 0;
      padding-right: 40px;
      line-height: 2;
      font-size: 14px;
      color: #666;
      position: absolute;
      bottom: -10px;
      p{
        margin-bottom: 0;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
      .ml-100{
        margin-left: 100px;
      }
    }
    a {
      font-size: 18px;
      color: #336fd0;
      float: right;
    }
  }
  // &-swiper {
  //   width: 1200px;
  //   background-size: auto;
  //   position: relative;
  //   /deep/ .swiper-container {
  //     width: 1200px;
  //   }
  // }
}
.ant-carousel{padding: 50px 0 50px;}
.ant-carousel .custom-slick-arrow {
  width: 36px;
  height: 62px;
  color: #fff;
  margin-top: -31px;
  &::before {
    display: none;
  }
  &.arrow-prev {
    background-image: url("http://www.lndfwljt.com/image/2021/07/09/ic-arrow-l.png");
    left: 20px;
    right: auto;
  }
  &.arrow-next {
    background-image: url("http://www.lndfwljt.com/image/2021/07/09/ic-arrow-r.png");
    right: 20px;
    left: auto;
  }
}
</style>
<style lang="less">
.swiper-slide {
  height: auto;
  &-item {
    position: relative;
  }
}
.banner-swiper.ant-carousel  .dots-click li {
  margin: 0 5px;
  button {
    width: 14px;
    height: 14px;
    border-radius: 50%;
    background-color: #aaacaf;
  }
}
.banner-swiper.ant-carousel .dots-click li.slick-active {
  button {
    width: 40px;
    height: 14px;
    background-color: #336fd0 !important;
    border-width: 0 !important;
    border-radius: 7px;
  }
}
.banner-swiper.ant-carousel .slick-dots-bottom {
  bottom: 0px;
}
</style>
