<template>
  <div class="main">
    <MainHeader />
    <Banner :bgImg="bgImg" :TopTitle="TopTitle" :secondTitle="secondTitle">
      <div class="banner-item w1200 align-center">
      </div>
    </Banner>
    <!-- 中间内容部分 -->
    <PartTitle :src="tit1" />
    <!-- part2 -->
    <Box type="style1">
      <div class="w1200 part2 container">
        <div class="l w35">
          <video controls autoplay style="position: center;width: 150%" src="http://www.lndfwljt.com/image/2021/07/09/物流.mp4" />
        </div>
        <div class="r w65 pl20">
          <ul class="list">
            <li>辽宁九丰药业有限公司成立于2015年，是一家集医药批发、物流配送、三方服务为主要业务的大型现代化医药流通企业，建设了15000平方米符合GSP标准的专业医药仓储库房和一千余立方米的标准冷库，年配送能力可达到50亿元。库房内采用了国际领先的机器人分拣技术，引进了激光导航无人小车（AGV）、打造“无人仓”实现多功能、一体化、智能化物理装备产品线，极大提升物流效率，降低物流成本。</li>
            <li>公司引进智能拣货分拨墙、自动化分拣系统，配备了ERP、WMS、DPS、WCS、TMS等先进的综合管理软件系统，实现仓储数字化、智能化管理，打造现代化智能医药物流仓储平台。</li>
            <li>公司拥有先进的物流仓储中心技术，具备完善的业务和管理流程，通过搭建网络销售+仓储物流相结合的线上线下平台，能够充分满足客户对物流快速、精准的仓储配送服务。</li>
            <li>同时公司还具有中药材、农副产品收购及销售、中草药种植、医疗器械销售及开发等业务。</li>
          </ul>
        </div>
      </div>
    </Box>
    <!-- part5 -->
    <Box type="style1">
      <div class="w1200 part3 container">
        <div class="l w65">
          <ul class="list">
            <li>公司具备药品经营许可证、医疗器械经营许可证、第二类医疗器械备案凭证、食品经营许可证、第三方（药品与器械）委托配送资格等资质，物流仓库全楼通过医保局GSP《药品经营质量管理规范》认证，被列为沈阳市医药仓储、流通标准库房。</li>
            <li>目前，物流仓储服务平台已经通过京东云仓考察认证，双方达成2000㎡的战略合作，作为东北地区独家京东医药商品储配中心，依托京东平台和云储系统实现智能物流服务。</li>
            <li>2020年，公司在门诊、社区医院共开户6000余家，覆盖辽宁、吉林、黑龙江、内蒙、河北等地区。同时，公司被纳入辽宁省医疗机构药品配送企业，具备了辽宁省临床配送资质，与辽宁中医等三甲医院开展业务合作，年销售近5亿。</li>
          </ul>
        </div>
        <div class="r w35">
          <img src="http://www.lndfwljt.com/image/2021/07/09/九丰药业.jpg" />
        </div>
      </div>
    </Box>
    <MainFooter />
  </div>
</template>
<script>
import MainFooter from '../public/footer'
import MainHeader from '../public/header'
import Banner from '../public/banner'
import PartTitle from '../public/box-title'
import Box from '../public/box'

export default {
  data () {
    return {
      bgImg: 'http://www.lndfwljt.com/image/2021/07/09/jiufeng.jpg',
      tit1: 'http://www.lndfwljt.com/image/2021/07/09/jiufeng.png',
      current: '1',
      TopTitle: '批发物流',
      secondTitle: 'WHOLESALE LOGISTICS'
    }
  },
  components: {
    MainFooter,
    MainHeader,
    Banner,
    PartTitle,
    Box
  }
}
</script>
<style lang="less" scoped>
  .banner-item {
    margin: 0 auto;
  }
  .part1 {
    line-height: 2.5;
    color: #555;
    margin-bottom: 40px;
    .row {
      margin-bottom: 40px;
    }
    text-align: center;
  }
  .w35 { width: 35% }
  .w65 { width: 45% }
  .pl20 { padding-left: 20px }
  .container {
    display: flex;
    justify-content: space-between;
    padding-bottom: 40px;
    img {
      width: 100%;
    }
    h4 {
      font-size: 20px;
    }
    .list {
      li {
        margin-bottom: 20px;
        position: relative;
        padding-left: 20px;
        &::before {
          content: ' ';
          position: absolute;
          left: 10px;
          top: 10px;
          width: 10px;
          height: 10px;
          margin-left: -5px;
          margin-top: -5px;
          background: #d2d2d2;
          border-radius: 50%;
        }
      }
    }
  }
</style>
