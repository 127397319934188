<template>
  <div class="main">
    <MainHeader />
    <Banner :bgImg="bgImg" :TopTitle="TopTitle" :secondTitle="secondTitle">
      <div class="banner-item w1200 align-center">
      </div>
    </Banner>
    <!-- 中间内容部分 -->
    <PartTitle :src="tit1" />
    <!-- part2 -->
    <Box type="style1">
      <div class="w1200 part2 container">
        <div class="l w35">
          <video controls autoplay style="position: center;width: 150%" src="http://www.lndfwljt.com/image/2021/07/09/易联.mp4" />
        </div>
        <div class="r w65 pl20">
          <ul class="list">
            <li>辽宁五洲易联生物科技有限公司成立于2020年5月，注册资本2000万元，占地面积800㎡，是大方未来集团全资子公司。公司集产品研发、生产加工、批发零售、服务推广为一体的全方位、综合化医疗机械生产服务平台。</li>
            <li>目前拥有专业的十万级净化车间，配备满足国家标准的无菌化医用口罩、N95口罩生产线，拥有专业检测实验室，配备生化培养箱、气相色谱仪、高压蒸汽灭菌器等实验检测设备。</li>
          </ul>
        </div>
      </div>
    </Box>
    <!-- part5 -->
    <Box type="style1">
      <div class="w1200 part3 container">
        <div class="l w65">
          <ul class="list">
            <li>公司以高端医疗器械生产研发平台的建设为目标，致力于成为医疗器械生产服务行业的领军企业，助力生物医药健康领域科技成果转化、创新项目落地。</li>
          </ul>
        </div>
        <div class="r w35">
          <img src="http://www.lndfwljt.com/image/2021/07/09/易联02.jpg" />
        </div>
      </div>
    </Box>
    <MainFooter />
  </div>
</template>
<script>
import MainFooter from '../public/footer'
import MainHeader from '../public/header'
import Banner from '../public/banner'
import PartTitle from '../public/box-title'
import Box from '../public/box'

export default {
  data () {
    return {
      bgImg: 'http://www.lndfwljt.com/image/2021/07/09/shengchan.jpg',
      tit1: 'http://www.lndfwljt.com/image/2021/07/09/shengchan.png',
      current: '1',
      TopTitle: '生产研发',
      secondTitle: 'PRODUCTION R & D'
    }
  },
  components: {
    MainFooter,
    MainHeader,
    Banner,
    PartTitle,
    Box
  }
}
</script>
<style lang="less" scoped>
  .banner-item {
    margin: 0 auto;
  }
  .part1 {
    line-height: 2.5;
    color: #555;
    margin-bottom: 40px;
    .row {
      margin-bottom: 40px;
    }
    text-align: center;
  }
  .w35 { width: 35% }
  .w65 {  width: 45%;margin-top: 130px }
  .pl20 { padding-left: 20px }
  .container {
    display: flex;
    justify-content: space-between;
    padding-bottom: 40px;
    img {
      width: 100%;
    }
    h4 {
      font-size: 20px;
    }
    .list {
      li {
        margin-bottom: 20px;
        position: relative;
        padding-left: 20px;
        &::before {
          content: ' ';
          position: absolute;
          left: 10px;
          top: 10px;
          width: 10px;
          height: 10px;
          margin-left: -5px;
          margin-top: -5px;
          background: #d2d2d2;
          border-radius: 50%;
        }
      }
    }
  }
</style>
