import { render, staticRenderFns } from "./article.vue?vue&type=template&id=0c5997a2&scoped=true&"
import script from "./article.vue?vue&type=script&lang=js&"
export * from "./article.vue?vue&type=script&lang=js&"
import style0 from "./article.vue?vue&type=style&index=0&id=0c5997a2&lang=less&scoped=true&"
import style1 from "./article.vue?vue&type=style&index=1&lang=css&"
import style2 from "./article.vue?vue&type=style&index=2&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0c5997a2",
  null
  
)

export default component.exports