<template>
  <div class="introduce">
    <div class="part1">
      <div class="w1200">
        <div class="detail">
          <div class="left">
            <img src="http://www.lndfwljt.com/image/2021/07/09/i2.png" />
            <div class="sub-title">
              <span class="one">Since</span><span class="two">2015</span><span class="three">医药健康产业领域服务商</span>
            </div>
            <div class="sub-com">
              大方未来集团致力于医药健康产业领域，集合医药零售连锁、医药批发物流、医药电子商务、医药生物科技、医药健康孵化、医药生产研发六大业务板块，建设现代化医药供应链物流配送基地，布局高端医疗器械中试生产基地，覆盖创新孵化、科技研发、生产制造、市场推广的医疗健康全产业链条。坚持以医药健康产业为主体，零售连锁与医药物流为两翼，围绕网络化、平台化、资本化的“一体、两翼、三化”战略方针，建设科技创新、智慧服务、产业升级、开放合作四大体系，坚持培育战略型新兴产业和创新型领军人才的发展愿景，打造国家级创新型医药健康产业IP孵化基地。
            </div>
          </div>
          <div class="com-icon">
            <img src="http://www.lndfwljt.com/image/2021/07/09/060502.jpg" />
          </div>
        </div>
      </div>
    </div>
    <div class="part2">
      <div class="w1200">
        <div class="title">
          <img src="http://www.lndfwljt.com/image/2021/07/09/i4.png" />
        </div>
        <div class="icon">
          <IconItem  v-for="(item, index) in ICON" :data="item" :key="index" />
        </div>
      </div>
    </div>
    <Bg :src="bg1" :height="600">
      <div class="relative" style="height: 600px">
        <div class="part3-item first">
          <div class="context">
            <div class="f1">企业愿景</div>
            <div class="f2">CORPORATE VISION</div>
            <div class="f3">
              高瞻远瞩，制胜未来<br/>
            </div>
          </div>
          <div class="part3-item-overlay" />
        </div>
        <div class="part3-item second">
          <div class="context">
            <div class="f1">企业使命</div>
            <div class="f2">CORPORATE MISSION</div>
            <div class="f3">
              坚定信念，果敢向前
            </div>
          </div>
          <div class="part3-item-overlay" />
        </div>
      </div>
    </Bg>
    <div class="part4">
      <div class="w1200">
        <div class="title">
          <img src="http://www.lndfwljt.com/image/2021/07/09/i8.png" />
        </div>
        <div class="container">
          <div class="item">
            <img src="http://www.lndfwljt.com/image/2021/07/09/060203.png" />
            <div class="detail-tit">药品经营质量管理规范认证</div>
          </div>
          <div class="item">
            <img src="http://www.lndfwljt.com/image/2021/07/09/060204.png" />
            <div class="detail-tit">医疗器械经营许可证</div>
          </div>
          <div class="item">
            <img src="http://www.lndfwljt.com/image/2021/07/09/060205.png" />
            <div class="detail-tit">食品经营许可证</div>
          </div>
        </div>
        <div class="container">
          <div class="item">
            <img src="http://www.lndfwljt.com/image/2021/07/09/zizhi02.jpg" />
            <div class="detail-tit">道路运输经营许可证</div>
          </div>
          <div class="item">
            <img src="http://www.lndfwljt.com/image/2021/07/09/zizhi05.jpg" />
            <div class="detail-tit">药品经营许可证</div>
          </div>
          <div class="item">
            <img src="http://www.lndfwljt.com/image/2021/07/09/zizhi09.jpg" />
            <div class="detail-tit">第二类医疗器械经营备案凭证</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Bg from '../../../public/bg'
import IconItem from './icon-item'
import { ICON } from './icon.json'

export default {
  components: {
    Bg,
    IconItem
  },
  data () {
    return {
      bg1: 'http://www.lndfwljt.com/image/2021/07/09/052901.png',
      ICON,
      TOP: true
    }
  },
  mounted () {
    window.scrollTo(0, 500)
  }
}
</script>
<style lang="less" scoped>
  .part1 {
    background: url('http://www.lndfwljt.com/image/2021/07/09/bg2.jpg') no-repeat;
    background-position: center center;
    background-color: rgb(243, 247, 250);
    background-size: 100%;
  }
  .detail {
    display: flex;
    width: 100%;
    padding-top: 40px;
    padding-bottom: 40px;
    .sub-title {
      margin-bottom: 20px;
      margin-top: 20px;
      .one {
        color: #000;
        font-weight: bold;
        font-style: italic;
        margin-right: 5px;
      }
      .two {
        font-size: 30px;
        color: #336fd0;
        font-style: italic;
        font-weight: bold;
        margin-right: 5px;
      }
      .three {
        color: #000;
        font-weight: bold;
      }
    }
    .sub-com {
      width: 90%;
      line-height: 30px;
      color: #333;
      text-indent: 40px;
      &:hover {
        color: #336fd0;
        cursor: pointer;
      }
    }
    .sub-row {
      margin-top: 20px;
      display: flex;
      .row-item {
        width: 140px;
        .value {
          font-size: 28px;
          color: #336fd0;
          font-weight: bold;
        }
      }
    }
    .left {
      flex: 1;
    }
    .com-icon {
      flex: 1;
    }
  }
  .com-icon {
    img {
      width: 100%;
    }
  }
  .part1 {
    padding-bottom: 20px;
  }
  .part2 {
    .title {
      margin: 0 auto;
      text-align: center;
    }
    .icon {
      display: flex;
      justify-content: space-around;
      margin-bottom: 40px;
      padding-top: 40px;
    }
  }
  .part3-item {
    width: 50%;
    height: 300px;
    background: rgba(255, 255, 255, 0.6);
    position: absolute;
    cursor: pointer;
    &.first {
      right: 0;
      top: 0;
      .context {
        padding: 60px;
        padding-left: 40px;
      }
    }
    &.second {
      left: 0;
      bottom: 0;
      display: flex;
      justify-content: flex-end;
      .context {
        padding: 70px;
        padding-left: 40px;
        min-width: 600px;
        max-width: 760px;
        width: 100%;
      }
    }
    .context {
      color: rgb(11, 69, 149);
      position: relative;
      z-index: 100;
      .f1 {
        font-size: 40px;
        font-weight: bold;
        margin-bottom: 5px;
      }
      .f2 {
        font-size: 20px;
        margin-bottom: 20px;
      }
      .f3 {
        font-size: 16px;
      }
    }
    &-overlay {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        height: 0;
        background: #529fcc;
        z-index: 1;
        transition: 300ms ease-in-out;
      }
    &:hover {
      .part3-item-overlay {
        height: 300px
      }
      .context {
        color: #fff;
      }
    }
  }
  .part4 {
    margin-top: 20px;
    .title {
      margin: 0 auto;
      text-align: center;
      margin-bottom: 40px;
    }
    .container {
      display: flex;
      justify-content: space-around;
      margin-bottom: 40px;
      .item {
        padding: 20px 20px;
        text-align: center;
        width: 30%;
        background: url('http://www.lndfwljt.com/image/2021/07/09/060206.png') no-repeat;
        background-size: cover;
        img {
          width: 75%;
          margin-bottom: 10px;
        }
      }
    }
  }
</style>
