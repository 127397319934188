<template>
  <div class="c-item" @mouseenter="enter" @mouseleave="leave">
    <div class="c-item-img">
      <img :src="item.src" />
    </div>
    <h3>{{ item.title }}</h3>
      <div v-show="visible" class="hover" :style="{ backgroundImage: `url(${item.hover.bg})`}">
    </div>
  </div>
</template>
<script>
export default {
  props: {
    item: {
      type: Object,
      default: () => ({})
    }
  },
  data () {
    return {
      visible: false
    }
  },
  methods: {
    enter () {
      this.visible = true
    },
    leave () {
      this.visible = false
    }
  }
}
</script>
<style lang="less" scoped>
  .hover {
    position: absolute;
    left: 0;
    bottom: 0;
    right: 0;
    top: 0;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    &-box {
      display: flex;
      height: 100%;
    }
    &-tit {
      flex: 1;
      background-repeat: no-repeat;
      background-size: auto 70px;
      background-position: 50% center;
    }
    &-bd {
      flex: 1;
      height: 100%;
      color: #fff;
      font-size: 18px;
      line-height: 2.5;
      word-spacing: 5px;
      letter-spacing: 4px;
      display: flex;
      align-items: center;
      padding-left: 20px;
    }
  }
  .c-item {
    width: 33%;
    min-height: 333px;
    cursor: pointer;
    &-img {
      text-align: center;
      margin: 0 auto;
      margin-bottom: 20px;
      width: 100px;
      height: auto;
      img {
        width: 100%;
      }
    }
    h3 {
      font-size: 18px;
      color: #000;
      font-weight: bold;
      text-align: center;
    }
    .lines {
      span {
        display: block;
        text-align: center;
        line-height: 2;
      }
    }
  }
</style>
