<template>
  <div class="banner">
    <div style="font-size: 72px" v-show="TopTitle !=null"><b>{{TopTitle}}</b></div>
    <div style="font-size: 36px" v-show="secondTitle !=null"><b>{{secondTitle}}</b></div>
    <div class="banner-inner" :style="style" />
    <div class="banner-slot">
      <slot />
    </div>
  </div>
</template>
<script>
export default {
  props: {
    bgImg: {
      type: String
    },
    TopTitle: {
      type: String
    },
    secondTitle: {
      type: String
    }
  },
  computed: {
    style () {
      return {
        backgroundImage: `url(${this.bgImg})`
      }
    }
  }
}
</script>
<style lang="less" scoped>
  .banner {
    min-width: 1200px;
    position: relative;
    &-inner {
      min-width: 1200px;
      background-repeat: no-repeat;
      background-position: center center;
      background-size: 100% 100%;
      padding-bottom: 48.333333%;
    }
    &-slot {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      top: 0;
      display: flex;
    }
    div:first-child {
    position: absolute;
    top: 30%;
    left: 11%;
    z-index: 100;
    color: white;
    letter-spacing:10px;
  }

    div:nth-child(2) {
      position: absolute;
      top: 47%;
      left: 11%;
      z-index: 100;
      color: white;
      letter-spacing:10px;
    }
  }
</style>
