export const data = [{
  title: '选人',
  summary: '凝聚有使命感，坚守底线，协同担当，讲奉献、肯付出的奋斗者。',
  bg: 'http://www.lndfwljt.com/image/2021/07/09/06195.jpg'
}, {
  title: '育人',
  summary: '德才兼备，以德为先。价值观是基础、品德是底线，绩效是分水岭，能力是必要条件。',
  bg: 'http://www.lndfwljt.com/image/2021/07/09/061906.jpg'
}, {
  title: '用人',
  summary: '猛将发于卒伍，宰相起于州郡。坚持从一线选拔、培养人才。',
  bg: 'http://www.lndfwljt.com/image/2021/07/09/061907.jpg'
}, {
  title: '留人',
  summary: '给火车头加满油，不让雷锋吃亏。<br/>营造适宜奋斗者成长的文化氛围及工作环境，发现并激励奋斗者在公司长期发展，共享价值。',
  bg: 'http://www.lndfwljt.com/image/2021/07/09/061908.jpg'
}]
