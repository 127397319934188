<template>
  <div class="main">
    <MainHeader />
    <Banner :bgImg="bgImg" :TopTitle="TopTitle" :secondTitle="secondTitle">
      <div class="banner-item w1200 align-center">
      </div>
    </Banner>
    <!-- 中间内容部分 -->
    <PartTitle :src="tit1" />
    <!-- part2 -->
    <Box type="style1">
      <div class="w1200 part2 container">
        <div class="l w35">
          <video controls autoplay style="position: center;width: 150%" src="http://www.lndfwljt.com/image/2021/07/09/空间.mp4" />
        </div>
        <div class="r w65 pl20">
          <ul class="list">
            <li>2018年5月，集团投入运营大方众创空间，空间用于孵化场地总面积为3000㎡，其中创业工位80个，公共服务场地面积2500㎡，使用率达83%。</li>
            <li>大方众创空间是定位于医药健康产业领域的专业化众创空间。</li>
            <li>公司以“创新驱动+资源整合+项目孵化+需求牵引”为核心，以生物医药为垂直领域，依托辽宁大方未来控股集团龙头企业，聚焦精准医疗、高端医疗器械、现代中药、生物制药、卫生健康、智慧医药物流、物联网、居家康养等领域内的高新技术、创新人才及科技成果，吸纳高新研发项目的入驻、孵化、推广及落地。</li>
            <li>空间秉承“专业孵化、价值创造、投资驱动、市场加速”的理念，搭建了成果转化、技术转移、精准对接、科技服务、金融投资、市场推广、产业孵化的创新创业平台。  </li>
          </ul>
        </div>
      </div>
    </Box>
    <!-- part5 -->
    <Box type="style1">
      <div class="w1200 part3 container">
        <div class="l w65">
          <ul class="list">
            <li>2019年，先后完成浑南区区级、沈阳市市级、辽宁省省级众创空间备案工作。</li>
            <li>空间与辽宁省中医养生康复学会、辽宁省创新孵化联盟、沈阳营养师协会、辽宁省医疗器械协会、天使投资联盟等达成战略合作协议，与辽宁医药职业学院建设高端医疗器械维修实训基地，搭建产学研合作、职业人才培养平台。</li>
            <li>通过积极探索成果对接、项目孵化、投资加速、市场助力等运营模式，打造“极客空间+科技孵化+创新加速”全链条的创业孵化平台，搭建链接科技匠心和创新创业的生态环境。</li>
          </ul>
        </div>
        <div class="r w35">
          <img src="http://www.lndfwljt.com/image/2021/07/09/空间.jpg" />
        </div>
      </div>
    </Box>
    <MainFooter />
  </div>
</template>
<script>
import MainFooter from '../public/footer'
import MainHeader from '../public/header'
import Banner from '../public/banner'
import PartTitle from '../public/box-title'
import Box from '../public/box'

export default {
  data () {
    return {
      bgImg: 'http://www.lndfwljt.com/image/2021/07/09/fuhua.jpg',
      tit1: 'http://www.lndfwljt.com/image/2021/07/09/fuhua.png',
      current: '1',
      TopTitle: '健康孵化',
      secondTitle: 'HEALTHY INCUBATION'
    }
  },
  components: {
    MainFooter,
    MainHeader,
    Banner,
    PartTitle,
    Box
  }
}
</script>
<style lang="less" scoped>
  .banner-item {
    margin: 0 auto;
  }
  .part1 {
    line-height: 2.5;
    color: #555;
    margin-bottom: 40px;
    .row {
      margin-bottom: 40px;
    }
    text-align: center;
  }
  .w35 { width: 35% }
  .w65 { width: 45% }
  .pl20 { padding-left: 20px }
  .container {
    display: flex;
    justify-content: space-between;
    padding-bottom: 40px;
    img {
      width: 100%;
    }
    h4 {
      font-size: 20px;
    }
    .list {
      li {
        margin-bottom: 20px;
        position: relative;
        padding-left: 20px;
        &::before {
          content: ' ';
          position: absolute;
          left: 10px;
          top: 10px;
          width: 10px;
          height: 10px;
          margin-left: -5px;
          margin-top: -5px;
          background: #d2d2d2;
          border-radius: 50%;
        }
      }
    }
  }
</style>
