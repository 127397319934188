export const data = [{
  lists: [{
    class: 'first-item',
    year: '2014',
    title: '辽宁五洲通大药房连锁有限公司成立'
  }, {
    year: '2015',
    title: '大方未来集团（辽宁大方未来控股集团有限公司）成立'
  }, {
    year: '2015',
    title: '辽宁九丰药业有限公司成立'
  }],
  text: [
    '2014-2015',
    '零售连锁与医药物流',
    '打造坚实基础'
  ],
  type: 0
}, {
  lists: [{
    year: '2017',
    title: '集团在沈阳市浑南区购置并建设大健康生物医药产业园'
  }, {
    year: '2017',
    title: '九丰药业建设了15000平方米符合GSP标准的专业医药仓储库房和一千余立方米的标准冷库'
  }, {
    year: '2017',
    title: '辽宁大方未来科技有限公司成立'
  }, {
    year: '2017',
    title: '大方未来科技入驻天猫商城、京东商城、饿百、美团外卖、拼多多等现代化电子商务平台'
  }],
  text: [
    '2016-2017',
    '建设大健康生物医药产业园',
    '电子商务崛起',
    '稳中求进 优化发展'
  ],
  type: 1
}, {
  lists: [{
    year: '2018',
    title: '辽宁九丰生物科技有限公司成立'
  }, {
    year: '2018',
    title: '大方众创空间成立'
  }, {
    year: '2018',
    title: '五洲通大药房品牌创新服务升级，与全国两千余家知名品牌药企建立了合作关系，经营品规达万余种'
  }, {
    year: '2019',
    title: '九丰生物与西班牙BIOSTEC等公司强强联手，打造尿特种蛋白及尿生化整体解决方案'
  }, {
    year: '2019',
    title: '众创空间先后完成浑南区区级、沈阳市市级、辽宁省省级众创空间备案'
  }],
  text: [
    '2018-2019',
    '多元业务发展',
    '打造医药健康产业'
  ],
  type: 0
}, {
  lists: [{
    year: '2020',
    title: '五洲通大药房面对严峻经济形势稳重求胜强势出击，连锁门店突破350家，会员数量近100万'
  }, {
    year: '2020',
    title: '辽宁五洲易联生物科技有限公司成立'
  }, {
    year: '2020',
    title: '九丰生物结合中国医科大学附属第一医院共建辽宁省肾脏病一体化管理及防治中心'
  }, {
    year: '2021',
    title: '五洲易联医用防护口罩、医用外科口罩、一次性使用医用口罩相继生产'
  }, {
    year: '2021',
    title: '九丰药业推出线上线下相结合的B2B线上医药、医疗联盟交流平台——九丰诊业联盟'
  }],
  text: [
    '2020-2021',
    '全面布局发展壮大',
    '深入医药六大板块业务'
  ],
  type: 1
}]
