<template>
  <div class="main">
    <PartTitle title="核心价值观" :src="tit1" />
    <div class="PartWord">
      <img src="http://www.lndfwljt.com/image/2021/07/09/四大体系.jpg"/>
    </div>
  </div>
</template>
<script>
import PartTitle from '../../../public/box-title'

export default {
  components: {
    PartTitle
  },
  data () {
    return {
      tit1: 'http://www.lndfwljt.com/image/2021/07/09/四大体系.png'
    }
  }
}
</script>
<style lang="less">
  .main {
  }
  .PartWord{
    margin-left: 220px;
  }
</style>
