<template>
  <div class="h-menus w1200">
    <div class="h-menus-inner">
      <Item v-for="(item,index) in menus"
        :data="item"
        :key="index"
        :current="current"
        @select="v => $emit('select', v)"
      />
    </div>
  </div>
</template>
<script>
import Item from './item'

export default {
  props: {
    menus: {
      type: Array,
      default: () => []
    },
    current: {
      type: [Number, String]
    }
  },
  components: {
    Item
  }
}
</script>
<style scoped lang="less">
  .h-menus {
    height: 80px;
    &-inner {
      display: flex;
      margin: 0 auto;
      height: 80px;
      justify-content: space-around;
    }
  }
</style>
