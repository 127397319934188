<template>
  <div>
    <div class="contact-side">
      <p class="tit">分享</p>
      <div class="flex-btwn">
        <a href=""><img src="http://www.lndfwljt.com/image/2021/07/09/io-wb.png" alt="微博"></a>
        <a href=""><img src="http://www.lndfwljt.com/image/2021/07/09/io-wechat.png" alt="微信"></a>
        <a href=""><img src="http://www.lndfwljt.com/image/2021/07/09/io-qq.png" alt="QQ"></a>
      </div>
    </div>
    <div class="contact-side">
      <p class="tit">推荐阅读</p>
      <ul>
        <li v-for='(item,index) in list' :key='index'>
          <a target="_blank" @click="goNew(item)"><p class="txt">{{item.title}}</p></a>
          <p class="date">{{item.createTime}}</p>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
import { fetchCompanyNewLists } from '@/api/news'

export default {
  data () {
    return {
      list: []
    }
  },
  async mounted () {
    const data = await fetchCompanyNewLists({ type: 1, pageNum: 1 })
    if (data.code === 200) {
      this.list = data.data.records
    }
  },
  methods: {
    goNew (item) {
      this.$router.push({
        name: 'newsDetail',
        params: {
          type: item.type,
          pageId: item.id
        }
      })
    }
  }
}
</script>
<style lang='less' scoped>
.contact-side {
  width:400px;
  margin-bottom:20px;
  padding:5px 25px 25px;
  background-color: #f4f6fa;
  .tit{
    margin-bottom: 20px;
    font-size:32px;
    color: #333;
    font-weight: bold;
    line-height: 3;
    border-bottom: 1px solid #6cbbe9;
  }
  li{
    padding-top: 20px;
    padding-bottom: 20px;
    font-size:16px;
    color: #333;
    line-height:1.6;
    .date{
      margin-top: 10px;
      font-size:14px;
      color: #555;
    }
  }
  li+li{
    border-top: 1px solid #ddd;
  }
}
</style>
