<template>
  <div class="main">
    <MainHeader />
    <MainBanner :src='bannerSrc' :TopTitle="TopTitle"  :secondTitle="secondTitle"/>
    <div class="mainWarp">
      <div class="title"><img src="http://www.lndfwljt.com/image/2021/07/09/title-social.png" alt="可持续性发展"></div>
      <p class="instructions">宏远控股集团在商业发展的同时，十分关注可持续发展，并将可持续发展这一内容融入到整体的企业发展战略中。宏远控股集团的使命是“让世界更近，生活更美好”，我们希望通过业务优势和特点，在带来美好物质生活的同时，能够与客户、合作伙伴以及员工等各方力量，一同激发可持续发展的思想力、管理力以及协同力。
      </p>
    </div>
    <div class="listBg">
      <div class="list row-r">
          <img class="right" src="http://www.lndfwljt.com/image/2021/07/09/social-11.jpg" />
          <div class="flex-btwn list-text">
            <div class="list-title li-title1">
              <div>
                <p class="f1">无偿运输</p>
                <p class="f2">Non-charge Trasportation</p>
              </div>
            </div>
            <div class="detail">
                <p class="tit">抗疫先锋，无偿运输</p>
                <p class="content">宏远控股集团旗下空港宏远，利用自己的物流资源，多次向社会各界提供无偿运输服务。2020年新冠肺炎疫情爆发期间，宏远利用自身航空干线，多次无偿运送防疫物资累计达百万件。
                </p>
            </div>
          </div>
      </div>
    </div>
    <div class="listBg">
      <div class="list row-l">
          <img class="left" src="http://www.lndfwljt.com/image/2021/07/09/social-12.jpg" />
          <div class="flex-btwn list-text">
            <div class="detail">
                <p class="tit">助燃希望，守护梦想</p>
                <p class="content">孩子是祖国未来的希望，守护孩子的梦想，是国家可持续发展的根本。自2014年以来，宏远便持续参与贫困助学活动，以各种形式关爱孩子，助燃希望。2020年，宏远更是把冬奥的精神带给了内蒙古巴林左旗的孩子们，让孩子们一起感受体育的力量，奥运的力量。
                </p>
            </div>
            <div class="list-title li-title2 flex-column">
              <p class="f1">扶贫助学</p>
              <p class="f2">Poverty Alleviation</p>
            </div>
          </div>
      </div>
    </div>
    <div class="listBg">
      <div class="list row-l">
          <img class="right" src="http://www.lndfwljt.com/image/2021/07/09/social-13.jpg" />
          <div class="flex-btwn list-text">
            <div class="list-title li-title3 flex-column">
              <p class="f1">绿色环保</p>
              <p class="f2">Environment Improvement</p>
            </div>
            <div class="detail">
                <p class="tit">绿色倡议，美丽明天</p>
                <p class="content">绿水青山，就是金山银山。宏远始终坚持绿色环保理念，并将这一理念落实到日常工作的细节中。由宏远控股集团打造的地产项目宏远航城广场在北京率先建立了智能垃圾分拣中心，切实做到“源头减排”。由宏远打造的北京冬奥会主物流中心更是获得美国LEED绿色环保认证。
                </p>
            </div>
          </div>
      </div>
    </div>
    <MainFooter />
  </div>
</template>
<script>
import MainFooter from '../public/footer'
import MainHeader from '../public/header'
import MainBanner from '../public/simpleBanner'
// import bannerImg from 'http://www.lndfwljt.com/image/2021/07/09/ressponsibility.jpg'

export default {
  data () {
    return {
      bannerSrc: 'http://www.lndfwljt.com/image/2021/07/09/ressponsibility.jpg',
      TopTitle: '社会责任',
      secondTitle: 'SOCIAL RESPONSIBILITY'
    }
  },
  watch: {
    '$route.params': {
      handler (params) {
        console.log('$route.params')
        console.log(params)
        if (params.type) {
          this.current = params.type
        }
      },
      deep: true
    }
  },
  created () {
    this.current = this.$route.params.type
  },
  methods: {
    handleSelect (v) {
      if (v === this.current) return
      this.current = v
      this.$router.push({
        name: 'Social',
        params: { type: v }
      })
    }
  },
  components: {
    MainFooter,
    MainHeader,
    MainBanner
    // Menus,
  }
}
</script>
<style lang="less" scoped>
.title {
  margin-top: 90px;
  margin-bottom: 60px;
  text-align: center;
}
.instructions{
  padding:0 60px 123px;
  font-size: 14px;
  color: #555;
  line-height: 2;
  ext-align: center;
  text-align: center;
}
.listBg{
  width:100%;
  height: 690px;
  min-width: 1200px;
  margin-bottom: 100px;
  background: url('http://www.lndfwljt.com/image/2021/07/09/bg2.jpg') no-repeat center top;
  background-size: auto 100%;
}
.list{
  width:100%;
  height: 100%;
  max-width: 1523px;
  min-width: 1200px;
  margin: 0 auto;
  padding: 0 60px;
  position: relative;
  display: flex;
  justify-content: center; /* 水平居中 */
  align-items: center;     /* 垂直居中 */
  img{
    width: 65.5%;
    height: auto;
    opacity: .15;
    background-color: #fff;
    position: absolute;
  }
  .right{
    right: 60px;
  }
  .left{
    left: 60px;
  }
  .list-text{
    align-items:center;
    &>*{
      width: 50%;
    }
  }
  .list-title{
    height: 300px;
    color: #fff;
    text-align: center;
    display: flex;
    justify-content: center; /* 水平居中 */
    align-items: center;     /* 垂直居中 */
    position: relative;
    .f1{
      font-size: 36px;
    }
    .f2{
      font-size: 24px;
    }
    &.li-title1{
      background-color: #326fba;
    }
    &.li-title2{
      background-color: #f8da78;
    }
    &.li-title3{
      background-color: #53ad5b;
    }
  }
}
.detail{
  padding: 50px;
  .tit{
    font-size: 16px;
    color: #333;
    font-weight: bold;
  }
  .content{
    font-size: 14px;
    color: #555;
    line-height: 2;
  }
}
  .fix-menus {
    margin-top: -90px;
  }
</style>
