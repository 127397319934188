<template>
  <div class="icon-item" v-on:click="goto(data.link)">
    <div class="image" :class="data.icon"></div>
    <div class="tit">{{ data.tit }}</div>
    <div class="subtit">{{ data.subTit }}</div>
  </div>
</template>
<script>
export default {
  props: {
    data: {
      type: Object,
      default: () => ({})
    }
  },
  methods: {
    className (item) {

    },
    goto (item) {
      this.$router.push({ path: item })
    }
  }
}
</script>
<style lang="less" scoped>
.icon {
  .icon-bg0 {
    background-image: url('http://www.lndfwljt.com/image/2021/07/09/i5-0.png');
  }
  .icon-bg1 {
    background-image: url('http://www.lndfwljt.com/image/2021/07/09/i5-1.png');
  }
  .icon-bg2 {
    background-image: url('http://www.lndfwljt.com/image/2021/07/09/i5-2.png');
  }
  .icon-bg3 {
    background-image: url('http://www.lndfwljt.com/image/2021/07/09/i5-3.png');
  }
  .icon-bg4 {
    background-image: url('http://www.lndfwljt.com/image/2021/07/09/i5-4.png');
  }
  .icon-bg5 {
    background-image: url('http://www.lndfwljt.com/image/2021/07/09/i5-5.png');
  }
  .icon-bg0:hover {
    background-image: url('http://www.lndfwljt.com/image/2021/07/09/i5-0-hover.png');
  }
  .icon-bg1:hover {
    background-image: url('http://www.lndfwljt.com/image/2021/07/09/i5-1-hover.png');
  }
  .icon-bg2:hover {
    background-image: url('http://www.lndfwljt.com/image/2021/07/09/i5-2-hover.png');
  }
  .icon-bg3:hover {
    background-image: url('http://www.lndfwljt.com/image/2021/07/09/i5-3-hover.png');
  }
  .icon-bg4:hover {
    background-image: url('http://www.lndfwljt.com/image/2021/07/09/i5-4-hover.png');
  }
  .icon-bg5:hover {
    background-image: url('http://www.lndfwljt.com/image/2021/07/09/i5-5-hover.png');
  }
  display: flex;
  justify-content: space-around;
  margin-bottom: 40px;
  &-item {
    text-align: center;
    .image {
      width: 100px;
      height: 100px;
      background-repeat: no-repeat;
      background-position: center center;
      background-size: 100px 100px;
      margin-bottom: 20px;
      margin-left: auto;
      margin-right: auto;
    }
    .tit {
      color: #336fd0;
      margin-bottom: 10px;
    }
    .subtit {
      position: relative;
      padding-bottom: 10px;
      font-weight: bold;
      color: #000;
      &:after {
        content: " ";
        position: absolute;
        width: 20px;
        background: #336fd0;
        height: 2px;
        border-radius: 1px;
        bottom: 0;
        left: 50%;
        margin-left: -10px;
      }
    }
  }
}
</style>
