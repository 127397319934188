<template>
  <div class="main">
    <MainHeader />
    <Banner :bgImg="bgImg" :TopTitle="TopTitle" :secondTitle="secondTitle">
      <div class="banner-item w1200 align-center">
      </div>
    </Banner>
    <!-- 中间内容部分 -->
    <PartTitle :src="tit1" />
    <Box type="style1">
      <div class="part1">
        <img src="http://www.lndfwljt.com/image/2021/07/09/061904.png" />
      </div>
    </Box>

    <!-- 修改 -->
    <div style="margin-bottom: 40px;">
    <Item v-for="(item, index) in data" :key="index"
      :title="item.title"
      :bg="item.bg"
      :summary="item.summary"
     />
    </div>
    <MainFooter />
  </div>
</template>
<script>
import MainFooter from '../public/footer'
import MainHeader from '../public/header'
import Banner from '../public/banner'
import PartTitle from '../public/box-title'
import Box from '../public/box'
import Item from './item.vue'

import { data } from './data.json'

export default {
  data () {
    return {
      bgImg: 'http://www.lndfwljt.com/image/2021/07/09/061902.jpg',
      tit1: 'http://www.lndfwljt.com/image/2021/07/09/061903.png',
      data,
      TopTitle: '人才理念',
      secondTitle: 'TALENT CONCEPT'
    }
  },
  components: {
    MainFooter,
    MainHeader,
    Banner,
    PartTitle,
    Box,
    Item
  }
}
</script>
<style lang="less" scoped>
  .banner-item {
    margin: 0 auto;
    img {
      vertical-align: middle;
    }
  }
  .part1 {
    padding: 10px 0 50px;
    text-align: center;
    font-size: 0;
    img {
      width: 500px;
    }
  }

</style>
